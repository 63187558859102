import React from "react";

function Title({
  type = "h1",
  children,
  fontClasses = "text-2xl md:text-3xl lg:text-4xl",
  className = ""
}) {
  const Tag = type;
  return (
    <Tag className={`text-blue-100 ${fontClasses} ${className}`}>
      {children}
    </Tag>
  );
}

export default Title;
