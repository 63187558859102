import { useEffect, useRef } from "react";
import React from "react";
import "./style.css";
import { initHeadline } from "./TextAnim";

export default function AnimatedHeadline() {
  useEffect(() => {
    initHeadline();
  }, []);

  return (
    <h1 className="phone-size:index-heading-ps lg:index-heading-lg index-heading cd-headline type letters">
      <span>Extensible web imaging platform</span>
      <br />
      <span>for </span>
      <div className="cd-words-wrapper waiting">
        <b className="is-visible">oncology</b>
        <b>cardiology</b>
        <b>veterinary medicine</b>
        <b>medical devices</b>
        <b>machine learning</b>
        <b>pathology</b>
      </div>
    </h1>
  );
}
