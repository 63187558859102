import React from "react";
import { graphql, Link } from "gatsby";

import Page from "./../components/Page";
import SEO from "./../components/SEO";
import EmailSubscription from "./../components/EmailSubscription";
import AnimatedHeadline from "../utils/AnimatedHeadline";
import HomePageImageCarousel from "../components/HomePageImageCarousel";
import Title from "../components/Title";

function ColumnContent({ header, body, linkText, link, href, className = "" }) {
  return (
    <div className={className}>
      <Title type="h1" className="mx-auto">
        {header}
      </Title>
      <p className="text-lg leading-normal mt-2 text-white">{body}</p>
      {link ? (
        <Link to={link}>
          <button className="flex items-center text-blue-bright mx-auto">
            {linkText}
            <span className="phone-size:right-arrow-ps lg:right-arrow-lg pl-1">
              &rarr;
            </span>
          </button>
        </Link>
      ) : (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <button className="flex items-center text-blue-bright mx-auto">
            {linkText}
            <span className="phone-size:right-arrow-ps lg:right-arrow-lg pl-1">
              &rarr;
            </span>
          </button>
        </a>
      )}
    </div>
  );
}

function IndexPage({ data }) {
  const imagesInfo = data.allHomepageScreenshotsJson.nodes;

  return (
    <Page>
      <SEO title="Open Health Imaging Foundation" />
      <div className="text-center bg-blue-800 mt-8">
        {/* INTRO */}
        <div className="bg-blue-800 relative z-30 inline-block align-middle w-11/12 mt-5">
          <AnimatedHeadline />
          <p className="mt-2 text-white text-base md:text-xl lg:text-xl">
            Create custom workflows with user-friendly interfaces.
            <br className="phone-size:hidden sm:block" />
            Review cases and report results quickly, zero installation required.
          </p>
          <div className="space-x-2 flex justify-center mt-7">
            <button className="phone-size:primary-button-ps lg:primary-button-lg hover:bg-hover-blue active:bg-active-blue">
              <a href="https://viewer.ohif.org">
                <div className="flex flex-row items-center">Try It Now</div>
              </a>
            </button>
            <button className="phone-size:secondary-button-ps lg:secondary-button-lg hover:bg-hover-blue active:bg-active-blue">
              <Link to="/features">
                <div className="flex flex-row items-center">Features</div>
              </Link>
            </button>
          </div>
        </div>
        <div className="px-3 md:px-8">
          <HomePageImageCarousel imagesInfo={imagesInfo} />
        </div>

        {/* FEATURES */}
        <div class="mt-16 md:mt-20 w-10/12 md:w-11/12 mx-auto grid phone-size:grid-cols-1 sm:grid-cols-2 phone-size:space-y-4 sm:space-y-8">
          <div class="sm:border-r-2 border-blue-section">
            <ColumnContent
              header="Features"
              body="Learn about the OHIF Viewer framework, core features, and extensibility"
              linkText="View Features"
              link="/features"
              className="phone-size:mt-4 sm:mx-4"
            />
          </div>
          <div class="border-t-2 sm:border-t-0 border-blue-section">
            <ColumnContent
              header="Learn"
              body="View a curated set of resources to get started using OHIF and find detailed documentation"
              linkText="Learn more"
              href="https://docs.ohif.org"
              className="phone-size:mt-4 sm:mx-4  sm:-mt-4"
            />
          </div>
          <div class="border-t-2 sm:border-t-0 sm:border-r-2 border-blue-section md:pt-6 lg:pt-0 lg:border-r-2">
            <ColumnContent
              header="Community"
              body="Collaborate, Contribute, and stay up-to date on the latest news and roadmap"
              linkText="Community resources"
              link="/collaborate"
              className="phone-size:mt-4 sm:mx-4"
            />
          </div>
          <div class="border-t-2 sm:border-t-0 border-blue-section md:pt-6 lg:pt-0">
            <ColumnContent
              header="Get Support"
              body="Get in touch to learn more about how we can help you with your project"
              linkText="Get Support"
              link="/get-support"
              className="phone-size:mt-4 sm:mx-4"
            />
          </div>
        </div>

        {/* NewsLetter */}
        <div className="flex w-10/12 mt-16 md:mt-24 mx-auto mb-24 flex-col flex-grow md:flex-row">
          <div className="text-left md:w-1/2 md:pr-10">
            <Title type="h1">Stay up to date</Title>
            <p className="text-white mt-6">
              Subscribe to the Newsletter to stay up-to-date with new releases,
              functionality, and contributions from the community.
            </p>
          </div>
          <div className="md:w-1/2 w-full mt-4 md:mt-0">
            <div>
              <EmailSubscription />
            </div>
            <div className="text-white text-left sm:-mt-2">
              See our{" "}
              <span className="text-blue-100 underline">
                <Link to="/news">previous newsletters</Link>
              </span>
              .
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export const query = graphql`
  {
    allHomepageScreenshotsJson {
      nodes {
        name
        id
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default IndexPage;
