import React, { useState, useEffect, useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";

const ImageCarousel = ({ imagesInfo }) => {
  const [activeImage, setActiveImage] = useState(imagesInfo[0]);
  const image = getImage(activeImage.image);
  const intervalId = useRef(null);

  const handleClick = (imageObj) => {
    setActiveImage(imageObj);
    clearInterval(intervalId.current);
    setUpInterval();
  };

  const setUpInterval = () => {
    intervalId.current = setInterval(() => {
      setActiveImage((prevImage) => {
        const currentIndex = imagesInfo.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % imagesInfo.length;
        return imagesInfo[nextIndex];
      });
    }, 8000);
  };

  useEffect(() => {
    setUpInterval();
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-white flex space-x-8 mt-16 mobile-carousel">
        {imagesInfo.map((imageObj, index) => (
          <button
            key={index}
            className={classNames(
              "hover:text-aqua-bright font-semibold relative",
              activeImage.name === imageObj.name
                ? "text-[#5acce6]"
                : "text-[#878787]"
            )}
            onClick={() => handleClick(imageObj)}
          >
            {imageObj.name}
            {activeImage.name === imageObj.name && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: "2px",
                  backgroundColor: "#5acce6",
                  animation: "homepage-progress 8s linear"
                }}
              />
            )}
          </button>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <GatsbyImage image={image} alt={activeImage.name} />
      </div>
    </div>
  );
};

export default ImageCarousel;
